body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #d22108;
  font-weight: bold;
}

.alignCentre {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.mt-3 {
  margin-top: 3rem;
}

@media (max-width: 350px) {
  .MuiBottomNavigation-root {
    justify-content: left;
  }

  .botNav {
    overflow: auto;
  }
}

.createBtn {
  position: fixed;
  bottom: 160px;
  right: 0px;
  transform: translateX(-50%);
  background-color: #d22108;
}

.chatbotBtn {
  position: fixed;
  bottom: 80px;
  right: 0px;
  transform: translateX(-50%);
  background-color: #d22108;
}

.createBtn:hover {
  background-color: #b31b07;
}

.chatbotBtn:hover {
  background-color: #b31b07;
}

.noti-icon {
  justify-content: flex-end;
  display: flex;
  float: right;
  margin-left: 10px;
}

.noti-icon:hover {
  background-color: unset;
}

@media (min-width: 600px) {
  .createBtn {
    right: 30px;
  }

  .chatbotBtn {
    right: 30px;
  }
}

.notifications-icon {
  color: #d22108;
  width: 10px;
  top: 0;
  display: block;
  right: 0;
}

.card {
  background-color: #fff3f3;
  border-radius: 10px;
  border: 1px solid #d22108;
  margin: 0.5rem;
  box-shadow: none;
}

.type-icon:hover {
  background-color: #ffe6e6;
}

.card-highlight {
  background-color: #fdc6c6;
}

.card-media {
  height: 70px;
  margin: auto;
  width: auto;
}

.card-title {
  line-height: 1.5em;
  height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #d22108;
  font-weight: bold;
  font-size: 1.4em;
  margin-top: 10px;
}

.details-title {
  color: #d22108;
  /* margin-top: 20px; */
  font-size: 1.4em;
  margin-bottom: 0;
  line-height: 1.4em;
}

.card-text {
  line-height: 1.5em;
  height: 3em;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  /* number of lines to show */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-bottom: 0;
  margin-top: 5px;
  color: black;
}

.filter-button {
  display: flex;
  border-radius: 20px;
  text-transform: none;
  align-items: center;
  color: #d22108;
  border: none;
}

.proximity {
  color: #6f6f6f;
  margin-bottom: 0;
  margin-top: 10px;
  font-weight: normal;
  font-size: 0.9em;
}

.add-button {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap-reverse;
  flex-direction: row-reverse;
}

.accept-button {
  background-color: #d22108;
}

.username {
  margin: 0.5rem 0 0 5px;
  padding: 1px 6px;
  align-items: center;
  border: solid #fff3f3;
  background-color: #fff3f3;
  color: #d22108;
  border-radius: 20px;
  width: fit-content;
  justify-content: center;
  display: flex;
}

/* .proximity-description {
    vertical-align: middle;
    align-items: right;
} */

.empty-feed {
  vertical-align: middle;
  text-align: center;
  position: absolute;
  width: 300px;
  height: 200px;
  z-index: 15;
  top: 50%;
  left: 50%;
  margin: -100px 0 0 -150px;
}

.empty-profile-requests {
  text-align: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.name {
  color: #d22108;
  font-weight: bold;
  font-size: 1.8em;
  margin: 10px 0%;
}

.profile-username {
  /* margin: 5%; */
  padding: 1px 6px;
  align-items: center;
  border: solid #d22108;
  background-color: #d22108;
  color: #fff3f3;
  border-radius: 20px;
}

.suggestion:hover {
  background-color: black;
  padding: 10px;
  color: white;
}

.banner-info {
  background-color: #f9d461;
  color: black;
  font-size: small;
  text-align: center;
  padding: 15px;
  line-height: 18px;
}

body .banner-info {
  font-size: 12px;
}