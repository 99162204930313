@media (max-width: 600px) {
  .report-dangers-fab {
    position: relative; /* change position from absolute to relative */
    bottom: auto; /* reset bottom value */
    left: auto; /* reset left value */
    margin: 1rem; /* add margin */
  }
}

@media(max-width:1024px){
  .report-dangers-fab {
      bottom:10% !important;
      left:10px !important;
  }
}
@media(max-width:991px){
  .report-dangers-fab {
      bottom:4% !important;
      left:5px !important;
  }
}
