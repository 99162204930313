.danger-cat-button {
    border: 1px solid red;
    border-radius: 25px;
    background: #fff;
    padding: 10px 15px;
    font-size: 18px;
    margin-left: 20px;
}
.danger-cat-button:hover{
    background:red;
    color:#fff;
} 
.danger-cat-button:focus{
    background:red;
    color:#fff;
} 