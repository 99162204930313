#warning-title {
  font-family: Roboto, Oxygen, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: #d22108;
  text-align: center;
  font-size: 24px;
  padding-top: 35px;
}

#warning-description {
  font-family: Roboto, Oxygen, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 400;
  text-align: left;
  font-size: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 15px;
  color: rgb(83, 100, 113);
}
