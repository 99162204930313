.fbLogo {
  background-color: RGB(66 103 170);
  color: white;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  height: 40px;
  width: 180px;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.googleLogo {
  background-color: white;
  color: black;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  height: 40px;
  width: 180px;
  flex-direction: row;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.twitterLogo {
  background-color: white;
  color: grey;
  box-shadow: rgb(0 0 0 / 24%) 0px 2px 2px 0px, rgb(0 0 0 / 24%) 0px 0px 1px 0px;
  border-radius: 2px;
  border: 1px solid transparent;
  font-size: 14px;
  font-weight: 500;
  font-family: Roboto, sans-serif;
  height: 45px;
  width: 180px;
}

.termsText {
  font-size: 14px;
  font-weight: 400;
  color: gray;
}

.linkText {
  color: #0000ee;
}