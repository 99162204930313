.map-container {
  height: 100vh;
  width: 100%;
}

.css-1o6z5ng:focus {
  outline: 0px;
}

.css-14lo706{
  float: unset;
  width: auto;
  overflow: hidden;
  display: block;
  padding: 0px;
  height: 11px;
  font-size: 0.75rem;
  visibility: hidden;
  max-width: 100%;
  transition:max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;
  white-space: nowrap;

}
