.url-link {
    font-size: 0.9rem;
    padding: 10px;
    border-radius: 3px;
    background-color: #FFD9C0;
    display: flex;
    align-items: center;
    color: #D22108;
    margin: auto;
  }
  
  .url-icon {
    fill: #fff;
    height: 25px;
    width: 25px;
    margin-right: 15px;
  }
  
  .url-header {
    font-size: 0.9rem;
  }